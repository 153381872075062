<script>
import Button from '../../components/reusable/Button.vue';

export default {
	name: 'NummernAustausch',
	components: {
        Button,
    },
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						'Es genügt nicht mehr, das zu machen, was vor 5 Jahren geklappt hat.',
				},
				{
					id: 2,
					bio:
						'Der Dating-Markt hat sich dahingehend verändert, dass Frauen vor allem online bedeutend mehr Auswahl haben. Du musst also lernen, aus der breiten Masse herauszustechen.',
				},
				{
					id: 3,
					bio:
						'Das schaffst du in dem du lernst, wie du Frauen im echtem Leben ansprichst und deine Online-Profile psychologisch richtig aufbaust. Achtung! Es reicht nicht aus nur hier und da ein paar Frauen anzusprechen oder ein Fotoshooting zu buchen. Du musst lernen wie du in einem Gespräch mit einer fremden Frau überzeugst!',
				},
				{
					id: 4,
					bio:
						'Deswegen musst du Flirten lernen! Wenn du zusätzlich Online-Dating nutzen möchtest, musst du deine Social Media Profile so aufbauen, dass du dich von der Konkurrenz abhebst!',
				},
			],
		};
	},
};
</script>

<template>
	<div>
		<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
			<!-- About details -->
			<div class="w-full sm:w-1/2 text-left sm:flex sm:items-center">
				<div>
					<p v-for="bio in bios" :key="bio.id"
						class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg sm:text-2xl"
						v-text="bio.bio">
					</p>
				</div>
			</div>

			<!-- About profile image -->
			<div class="w-full sm:w-1/2 flex items-center">
				<img src="@/assets/images/nummernAustausch.jpg" class="w-full h-full object-cover"
					alt="Hendrik tauscht Nummern aus" />
			</div>
		</div>
		<br />
		<br />

		<!-- Load calendar button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<a href="https://calendly.com/hendrik-mati/30min"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				target="__blank">
				<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
			</a>
		</div>
	</div>
</template>

<style scoped>
@media (min-width: 640px) {
	.object-cover {
		object-fit: cover;
	}
}
</style>