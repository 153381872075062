<template>
  <div :class="youtubeClass">
    <div class="frameWrapper">
      <iframe 
        class="frameContent"
        loading="lazy"
        :srcdoc="iframeSrcdoc"
        :src="iframeSrc"
        :title="title"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
      </iframe>
    </div>
  </div>
</template>

<style>
  .frameWrapper
  {
    position: relative; 
    padding-bottom: 56.15%; 
    height: 0; 
    overflow: hidden;
  }
  .frameContent
  {
    position: absolute; 
    top: 0; 
    left:0; 
    width: 100%; 
    height: 100%; 
    border: 0;
  }
</style>

<script>
export default {
  props: {
    youtubeUrl: {
      type: String,
      required: true
    },
    youtubeClass: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: 'Youtube Video'
    }
  },
  computed: {
    iframeSrc() {
      return `https://www.youtube.com/embed/${this.youtubeUrl.split('/').pop()}?autoplay=1&t=0`;
    },
    iframeSrcdoc() {
      return `
        <style>
          * {
          padding: 0;
          margin: 0;
          overflow: hidden;
          }
          
          body, html {
            height: 100%;
          }
          
          img, svg {
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;
            margin: auto;
          }
            
          svg {
            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
            transition: all 250ms ease-in-out;
          }
            
          body:hover svg {
            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
            transform: scale(1.2);
          }
        </style>
        <a href='${this.iframeSrc}'>
          <img src='https://img.youtube.com/vi/${this.youtubeUrl.split('/').pop()}/maxresdefault.jpg' alt='${this.title}'>
          <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
        </a>
      `
    }
  }
}
</script>