<script>
import Button from '../../components/reusable/Button.vue';
export default {
	name: 'CallToAction',
	components: {
		Button,
	},
};
</script>

<template>
	<section class="callToAction">
		<div class="specialBackground">
			<div class="container mx-auto flex sm:justify-between items-center pt-44 sm:flex-row h-screen">
				<!-- Banner right contents -->
				<div class="w-full">
					<p
						class="font-general-medium mt-2 text-lg text-center leading-none text-white text-4xl sm:text-8xl">
						Worauf wartest du?
					</p>
					
					<!-- Load calendar button -->
					<div class="mt-2 sm:mt-5 flex justify-center">
						<a href="https://calendly.com/hendrik-mati/30min"
							class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
							target="__blank">
							<Button title="Buche jetzt dein kostenloses Beratungsgespr&auml;ch" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style scoped>
.callToAction {
	background: url(@/assets/images/background_callToAction.jpeg) no-repeat center center / cover scroll;
	margin-bottom: 0;
}
</style>
