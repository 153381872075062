<script>
import AboutClientSingle from "./AboutClientSingle.vue";
import Button from '../../components/reusable/Button.vue';

export default {
	components: {
		AboutClientSingle,
		Button,
	},
	data: () => {
		return {
			clientsHeading:
				"Schau dir in diesen Videos an, was Teilnehmer unseres Mentoring-Programms sagen:",
			clients: [
				{
					id: 1,
					name: "Valentin",
					work: "Human Resources Manager",
					challenges: "Große Blockaden Frauen aktiv anzusprechen.",
					goals: "Langfristig eine perfekt passende Partnerin zu finden",
					results:
						"Viele Dates mit unterschiedlichen Frauen aus denen er nun auswählen kann.",
					youtubeId: "8CiDLvW5I5M",
				},
				{
					id: 2,
					name: "Tim",
					work: "Sales Manager",
					challenges:
						"Als introvertierter Mann das Datingleben selbst in die Hand nehmen können.",
					goals:
						"Locker auf Frauen auf der Straße zugehen zu können und diese auf Dates zu bewegen.",
					results:
						"Keine Ansprechangst mehr und viele Dates mit sehr attraktiven Qualitätsfrauen.",
					youtubeId: "ISllnIGRBJw",
				},
				{
					id: 3,
					name: "Leon",
					work: "Selbstständig",
					challenges:
						"Hatte bereits ein anderes Coaching und wollte noch mehr aus sich rausholen.",
					goals:
						"Authentisches Frauen ansprechen um die richtigen Frauen in sein Leben zu ziehen.",
					results:
						"Viele Dates und sehr natürliches ansprechen von Frauen im Alltag",
					youtubeId: "hdOns21wPFM",
				},
				{
					id: 4,
					name: "Michel",
					work: "IT Consultant",
					challenges:
						"Wenig Erfahrung mit Frauen, weil Fokus auf dem Studium lag.",
					goals: "Mehr Dates mit Frauen, die er aktiv angesprochen hat.",
					results:
						"Dated 2 Frauen parallel und lernt regelmäßig neue Frauen kennen.",
					youtubeId: "aXwP3ZZJ44o",
				},
				{
					id: 5,
					name: "Luca",
					work: "Pilot",
					challenges: "Nach seiner Trennung wieder neue Frauen kennenlernen.",
					goals:
						"Viele Dates mit passenden Frauen, um langfristig die Richtige zu finden.",
					results: "Zahlreiche Dates mit Frauen auf Augenhöhe.",
					youtubeId: "haYyLlSZO7M",
				},
				{
					id: 6,
					name: "Steven",
					work: "Student",
					challenges:
						"Ansprechangst und wenig Erfahrung mit Flirten im Alltag.",
					goals:
						"Ansprechangst minimieren und den Direktkontakt mit Frauen im Alltag lernen.",
					results:
						"Mentale und optische Weiterentwickliung + Dates mit Frauen aus dem Alltag.",
					youtubeId: "g2rfkJuGnjo",
				},
				{
					id: 7,
					name: "Kurt",
					work: "Selbstständig",
					challenges:
						"Als frischer Single wieder neue, attraktive Frauen kennenlernen.",
					goals:
						"Ansprechangst besiegen und Dates mit Frauen aus dem Direktkontakt im Alltag.",
					results: "Dates mit 3 attraktiven Frauen parallel.",
					youtubeId: "yaqfjf65sEo",
				},
				{
					id: 8,
					name: "Steffen",
					work: "Fotograf",
					challenges: "Wenig Zeit um aktiv Frauen kennenzulernen.",
					goals: "Zeiteffizient 2 Dates pro Woche.",
					results:
						"16 Dates mit verschiedenen Frauen nach 8 Wochen und viele aufregende Liebesnächte.",
					youtubeId: "36YoYCKr2E4",
				},
				{
					id: 9,
					name: "Kristen",
					work: "IT-Consultant",
					challenges: "Probleme beim Ansprechen von Frauen im Alltag.",
					goals: "Authentisches Flirten mit fremden Frauen und viele Dates.",
					results:
						"An nur einem Tag, durch das Ansprechen im Alltag, 8 neue Frauen kennengelernt.",
					youtubeId: "85pKuDgO3F4",
				},
				{
					id: 10,
					name: "Clemens",
					work: "Selbstständig",
					challenges: "Nach langer Beziehung wieder neue Frauen kennenlernen.",
					goals:
						"Mehrere Dates mit attraktiven Frauen um die Richtige zu finden.",
					results:
						"Nach nur 3 Wochen viele Dates und 3 Frauen die Michael intensiver kennengelernt hat.",
					youtubeId: "JBI_5pfixPY",
				},
			],
		};
	},
};
</script>

<template>
	<div class="mt-5 sm:mt-10">
		<p class="font-general-medium text-2xl sm:text-3xl text-primary-dark dark:text-primary-light">
			{{ clientsHeading }}
		</p>
		<div class="grid sm:grid-cols-2 grid-cols-1 mt-10 sm:mt-14 gap-2">
			<AboutClientSingle v-for="client in clients" :key="client.id" :client="client" />
		</div>

		<!-- Load calendar button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<a href="https://calendly.com/hendrik-mati/30min"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				target="__blank">
				<Button title="Buche jetzt dein kostenloses Beratungsgespr&auml;ch" />
			</a>
		</div>
	</div>
</template>
