<script>
import Button from '../../components/reusable/Button.vue';

export default {
	name: 'AboutHendrik',
	components: {
		Button,
	},
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						'Hendrik unterstützt europaweit Männer dabei, ein aufregendes Dating-Leben zu führen, um dabei langfristig die perfekte Partnerin zu finden und zu behalten.',
				},
				{
					id: 2,
					bio:
						'Seine Kunden sind dabei meistens Männer, die in der Vergangenheit den Fokus mehr auf ihrer Arbeit und anderen Lebensbereichen hatten und dadurch das Thema Frauen in den Hintergrund geraten ist.',
				},
				{
					id: 3,
					bio:
						'Auch Männer, die aus langen Beziehungen gekommen sind und wieder neue, attraktive Frauen kennenlernen wollen, gehören zu seinen häufigsten Klienten.',
				},
				{
					id: 4,
					bio:
						'Hendrik lernte die nötigen Fähigkeiten vor vielen Jahren auf eigene Faust, weil er als introvertierter und unattraktiver junger Mann nie bei den Frauen gelandet ist, die er wirklich haben wollte.',
				},
				{
					id: 5,
					bio:
						'Er musste dafür über Jahre hinweg, Tausende von Frauen ansprechen und Hunderte von Ablehnungen erfahren, bis er endlich zu dem Erfolg gekommen ist, den er sich gewünscht hat.',
				},
				{
					id: 6,
					bio:
						'Dabei stellte er fest, dass es immer dieselben Abläufe sind, die zum Erfolg führen.',
				},
				{
					id: 7,
					bio:
						'Genau diese klaren psychologischen Abläufe eines perfekten Flirts und die dazugehörigen Schritte der persönlichen Weiterentwicklung, bringt er nun binnen weniger Wochen den Männern bei, die ihm sein Vertrauen schenken.',
				},
				{
					id: 8,
					bio:
						'In den letzten Jahren reiste er durch Europa und betreute Hunderte Männer online und offline in einem sehr persönlichen und individuellen Coaching-Programm.',
				},
			],
		};
	},
};
</script>

<template>
	<div>
		<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
			<!-- About details -->
			<div class="w-full sm:w-3/4 text-left">
				<p class="font-general-medium py-1 text-2xl sm:text-3xl text-primary-dark dark:text-primary-light">
					Über Hendrik
				</p>
				<p v-for="bio in bios" :key="bio.id"
					class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg"
					v-html="bio.bio">
				</p>
			</div>

			<!-- About profile image -->
			<div class="w-full sm:w-2/4 flex items-center">
				<img src="@/assets/images/hendrik.jpg" class="w-full h-full object-cover"
					alt="Hendrik steht hinter einer Türe und schaut in die Ferne" />
			</div>
		</div>
		<br />

		<!-- Load calendar button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<a href="https://calendly.com/hendrik-mati/30min"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				target="__blank">
				<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
			</a>
		</div>
	</div>
</template>

<style scoped></style>
