<script>
import Button from '../../components/reusable/Button.vue';

export default {
	components: {
        Button,
    },
	setup() {
		return {
			vorgehen: [
				{
					id: 1,
					title: 'Analyse der Ist Situation',
					content: 'Wir analysieren zuerst deine aktuelle Situation und schauen uns gemeinsam an, welche Ziele du in den kommenden Wochen erreichen möchtest und welche Schwierigkeiten dir dabei noch im Weg stehen. Darauf basierend erstellen wir eine individuelle Strategie, die dich schnellst möglichst zu mehr Dates mit attraktiven Frauen führt.',
					icon: "individuellFahrplan"
				},
				{
					id: 2,
					title: 'Fundament legen',
					content: 'Bevor es in die Praxis geht, gibt es eine individuelle Vorbereitung, um den Weg zum Ziel enorm zu beschleunigen. Je nach Engpass widmen wir uns hier Techniken aus dem Bereich Mental-Training, um unterbewusste Blockaden zu beseitigen, der Outfitoptimierung, damit du noch besser bei Frauen ankommst und auch Techniken im Bereich Rhetorik und Körpersprache, damit du lernst dich noch besser zu vermarkten.',
					icon: 'transformation'
				},
				{
					id: 3,
					title: 'Aktiv auf Frauen zugehen',
					content: 'Im nächsten Schritt wirst du dabei begleitet, deine erste Praxiserfahrung zu sammeln. Du wirst zu Beginn an die Hand genommen, damit du dich beim Frauen ansprechen nicht alleine fühlen musst. Auch bekommst du von mir für jede Praxiseinheit einen individuellen Leitfaden an die Hand, damit du genau weißt, wie du mit deinen inneren Widerständen umgehst und wie du erfolgreiche Gespräche mit Frauen gestaltest.',
					icon: 'vorbereitungPraxis'
				},
				{
					id: 4,
					title: 'Analyse und Verbesserung',
					content: 'Wir werden deine Gespräche regelmäßig gemeinsam analysieren, um herauszufinden was deine nächsten Schritte sind und wie du deinen Erfolg beim aktiven Frauen ansprechen noch weiter optimieren kannst. Durch stetiges Feedback, wirst du schnell die nötigen Flirtskills beherrschen und dadurch auch in der Lage sein, spontan Frauen in Alltagssituationen kennenzulernen.',
					icon: 'aktionPraxis'
				},
				{
					id: 5,
					title: 'Optimierung Online-Auftritt',
					content: 'Ob du Frauen durch das Ansprechen oder Online kennenlernen willst, in beiden Fällen ist es wichtig deinen Online-Auftritt zu optimieren. Dafür erstellen wir Bilder, die du sowohl für Online-Dating-Apps als auch für deine digitale Visitenkarte Instagram nutzen kannst. Durch psychologisch richtig aufgebaute Online-Profile stellen wir deine Qualitäten in den Vordergrund und die Anzahl an Dates wird sich dadurch drastisch erhöhen.',
					icon: 'profile'
				},
				{
					id: 6,
					title: 'Erfolgreich Chatten',
					content: 'Mit meinem einzigartigen "Chat2Date-Framework" lernst du, wie du richtig mit Frauen chattest und darüber spielerisch leicht Dates ausmachst. Zusätzlich analysieren wir regelmäßig gemeinsam deine Chat-Verläufe, damit du auch an Praxisbeispielen lernst, wie du mit Frauen richtig per Text oder Sprachnachricht flirtest.',
					icon: 'chatting'
				},
				{
					id: 7,
					title: 'Date-Verführung-Beziehung',
					content: 'Erfolgreiche Dates mit Frauen sind planbar. Ich bringe dir bei, wie du dich auf einem Date richtig verhältst, wie du dich selbst vermarktest und wie du eine Spannung zwischen dir und der Frau entstehen lässt. Mit meiner Strategie wirst du Frauen innerhalb der ersten 1 bis 3 Dates verführen können. Natürlich lernst du auch, wie du eine Frau die dir besonders gut gefällt, für eine Beziehung gewinnen kannst.',
					icon: 'perfektion'
				},
			],
		};
	},
};
</script>

<template>
	<!-- Content -->
	<section class="pt-10 sm:pt-14 container">
		<!-- Title -->
		<div class="text-center">
			<p
				class="text-2xl sm:text-5xl font-medium mb-2 text-ternary-dark dark:text-ternary-light">
				Meine Erfolgstrategie
			</p>
		</div>

		<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20" v-for="vorgeh in vorgehen" :key="vorgeh.id">
			<!-- Image Left -->
			<div class="w-full sm:w-2/4 mb-7 sm:mb-0 hidden md:block" v-if="vorgeh.id % 2 != 0">
				<img :src="require(`@/assets/images/${vorgeh.icon}.svg`)" class="w-96" :alt="vorgeh.id"  />
			</div>

			<!-- Content -->
			<div class="w-full sm:w-2/4 text-left">
				<p class="font-general-medium py-1 text-2xl sm:text-3xl text-primary-dark dark:text-primary-light">
					{{ vorgeh.id }}. {{ vorgeh.title }}
				</p>
				<p class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg">
					{{ vorgeh.content }}
				</p>
			</div>

			<!-- Image Right -->
			<div class="w-full sm:w-2/4 mb-7 sm:mb-0 hidden md:block" v-if="vorgeh.id % 2 == 0">
				<img :src="require(`@/assets/images/${vorgeh.icon}.svg`)" class="w-96" :alt="vorgeh.id" />
			</div>

			<!-- Image Right Mobile -->
			<div class="w-full sm:w-2/4 mb-7 sm:mb-0 md:hidden">
				<img :src="require(`@/assets/images/${vorgeh.icon}.svg`)" class="w-96" :alt="vorgeh.id" />
			</div>
		</div>
		<br />

		<!-- Load calendar button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<a href="https://calendly.com/hendrik-mati/30min"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				target="__blank">
				<Button title="Buche jetzt dein kostenloses Beratungsgespr&auml;ch" />
			</a>
		</div>
	</section>
</template>

<style scoped></style>
