<script>
import Button from '../../components/reusable/Button.vue';

export default {
	components: {
		Button,
	},
	data: () => {
		return {
			bios: [
				{
					id: 'I',
					title: 'Mehr Dates mit attraktiven Frauen',
					bio: 'Mit meiner Strategie wirst du sowohl durch das Frauen ansprechen im Alltag sowie durch Online-Dating regelmäßig neue, attraktive Frauen kennenlernen.',
				},
				{
					id: 'II',
					title: 'Persönliche Transformation',
					bio: 'Durch mein Coaching wirst du lernen, dich selbstsicher zu vermarkten. Wir optimieren zudem gemeinsam deinen Außen-Auftritt, deine digitale Visitenkarte, deine mentale Stärke und deine kommunikativen Fähigkeiten.',
				},
				{
					id: 'III',
					title: 'Selbstbestimmt eine Partnerin für eine langfristige Beziehung auswählen',
					bio: 'Du wirst regelmäßig Dates mit Qualitätsfrauen haben und dadurch ein Gespür dafür bekommen, welche davon auch für eine langfristige Beziehung geeignet sind.',
				},
			],
		};
	},
};
</script>

<template>
	<div>
		<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
			<!-- About profile image -->
			<div class="w-full sm:w-2/4 flex items-center">
				<img src="@/assets/images/achievements.jpg" class="w-full h-full object-cover"
					alt="Hendrik flirtet beim Essen mit einer Frau" />
			</div>

			<!-- About details -->
			<div class="w-full sm:w-3/4 text-left">
				<p class="font-general-medium py-1 text-2xl sm:text-3xl text-primary-dark dark:text-primary-light">
					Was erreichst du in einem Coaching bei Hendrik Mati?
				</p>
				<br />
				<div v-for="bio in bios" :key="bio.id"
					class="flex ml-4 font-general-regular mt-4 mb-4 text-ternary-dark dark:text-ternary-light text-lg">
					<div class="flex flex-col pl-4">
						<p class="text-md text-ternary-dark mb-1 text-2xl sm:text-2xl mt-2 font-bold dark:text-ternary-light">
							{{ bio.title }}
						</p>
						<p class="text-md text-ternary-dark mt-2 mb-2 mr-3 dark:text-ternary-light">{{ bio.bio }}</p>
					</div>
				</div>
			</div>

		</div>
		<br />

		<!-- Load calendar button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<a href="https://calendly.com/hendrik-mati/30min"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				target="__blank">
				<Button title="Buche jetzt dein kostenloses Beratungsgespr&auml;ch" />
			</a>
		</div>
	</div>
</template>