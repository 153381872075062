<script>

export default {
	name: 'Buehne',
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						'Die meisten Männer im mittleren Alter, die aus einer langjährigen Beziehung kommen, haben ein Problem damit, eine neue Partnerin zu finden, oder überhaupt regelmäßig Dates zu bekommen.',
				},
				{
					id: 2,
					bio:
						'Sie glauben, dass sie schon früher oder später ein paar Frauen im Alltag, beim Weggehen oder online kennenlernen werden und es irgendwie von selbst klappt.',
				},
				{
					id: 3,
					bio:
						'Die Wahrheit ist, dass sich der Dating-Markt durch Online-Dating und Instagram maßgeblich geändert hat. Jede halbwegs attraktive Frau wird rund um die Uhr kontaktiert und die Konkurrenz hat sich quasi verhundertfacht.',
				},
				{
					id: 4,
					bio:
						'Frauen haben die Qual der Wahl. Deshalb bekommen "normale Männer" heutzutage kaum noch Dates mit Frauen und einige wenige Männer, die es drauf haben, haben eine große Auswahl.',
				},
			],
		};
	},
};
</script>

<template>
	<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
		<!-- Buehne profile image -->
		<div class="w-full sm:w-1/2 flex items-center">
			<img src="@/assets/images/buehne.jpg" class="w-full h-full object-cover"
				alt="Hendrik hält einen Vortrag auf der Bühne" />
		</div>

		<!-- Buehne details -->
		<div class="w-full sm:w-1/2 text-left sm:flex sm:items-center">
			<div>
				<p v-for="bio in bios" :key="bio.id"
					class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg sm:text-2xl"
					v-text="bio.bio">
				</p>
			</div>
		</div>

	</div>
</template>

<style scoped></style>
