<script>
import Button from '../../components/reusable/Button.vue';

export default {
    name: 'AboutHendrik',
    components: {
        Button,
    },
}
</script>

<template>
    <div class="mt-10 sm:mt-20">
        <div class="font-general-regular container mx-auto block sm:flex sm:justify-between sm:items-center">
            <div class="w-full">
                <p id="introText"
                    class="font-general-medium mt-2 text-lg text-center leading-none text-ternary-dark dark:text-ternary-light text-4xl sm:text-4xl">
                    Du erkennst dich in einer oder mehrerer dieser Schwierigkeiten wieder und weißt nicht wie du diese
                    alleine lösen kannst?
                </p>
                <br />

                <!-- Load calendar button -->
                <div class="mt-2 sm:mt-5 flex justify-center">
                    <a href="https://calendly.com/hendrik-mati/30min"
                        class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
                        target="__blank">
                        <Button title="Buche jetzt dein kostenloses Beratungsgespr&auml;ch" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#introText {
	line-height: 2.8rem;
}
</style>