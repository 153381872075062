<script>
import feather from 'feather-icons';
import LazyYoutube from "@/components/reusable/LazyYoutube.vue";

export default {
	components: {
		LazyYoutube
	},
	props: ['client'],
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	}
};
</script>

<template>
	<div class="text-left py-5 px-5 mb-8 dark:bg-secondary-light">
		<LazyYoutube :youtubeUrl="client.youtubeId" :youtubeClass="'w-full'"
			:title="'Kundenfeedback Premium-Mentoring'" />
		<div class="font-general-medium ">
			<div class="clientDetails">
				<p class="text-2xl">{{ client.name }}</p>
				<p>{{ client.work }}</p>
			</div>
			<div class="clientRating">
				<i v-for="i in 5" v-bind:key="i" :data-feather="'star'" class=""></i>
			</div>
			<br />
			<div class="clientInfo">
				<ul>
					<li><b>Herausforderung:</b> {{ client.challenges }}</li>
					<li class="py-1"><b>Ziel:</b> {{ client.goals }}</li>
					<li><b>Ergebnis:</b> {{ client.results }}</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style scoped>
.clientRating svg {
	display: initial;
	color: #FF9518;
	fill: #Ef8201;
}
</style>
