<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear()
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center">
		<div class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light">
			&copy; {{ copyrightDate }} by Hendrik Mati - All rights reserved. |
			<router-link to="/impressum"
				class="font-general-medium hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 duration-500">
				Impressum
			</router-link>
			|
			<router-link to="/datenschutz"
				class="font-general-medium hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 duration-500">Datenschutz
			</router-link>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
