<script>
import AppBanner from '@/components/shared/AppBanner';
import HomeVideo from '@/components/home/HomeVideo';
import Buehne from '@/components/home/Buehne';
import NummernAustausch from '@/components/home/NummernAustausch';
import SchwierigkeitenMaenner from '@/components/home/SchwierigkeitenMaenner';
import FirstCallToAction from '@/components/home/FirstCallToAction';
import Achievements from '@/components/home/Achievements';
import Erfolgsstrategie from '@/components/home/Erfolgsstrategie';
import AboutClients from '@/components/about/AboutClients';
import AboutHendrik from '@/components/home/AboutHendrik';
import CallToAction from '@/components/home/CallToAction';

export default {
	name: 'Home',
	components: {
		AppBanner,
		HomeVideo,
		Buehne,
		NummernAustausch,
		SchwierigkeitenMaenner,
		FirstCallToAction,
		Achievements,
		Erfolgsstrategie,
		AboutClients,
		AboutHendrik,
		CallToAction,
	},
};
</script>

<template>
	<div>
		<!-- Banner -->
		<div>
			<AppBanner class="mb-5 sm:mb-8" />
		</div>

		<!-- HomeVideo-->
		<div class="container mx-auto">
			<HomeVideo class="mb-5 sm:mb-8" />
		</div>

		<!-- Buehne -->
		<div class="container mx-auto">
			<Buehne class="mb-5 sm:mb-8" />
		</div>

		<!-- NummernAustausch -->
		<div class="container mx-auto">
			<NummernAustausch class="mb-5 sm:mb-8" />
		</div>

		<!-- SchwierigkeitenMaenner -->
		<div>
			<SchwierigkeitenMaenner class="mb-5 sm:mb-8" />
		</div>

		<!-- FirstCallToAction -->
		<div>
			<FirstCallToAction class="mb-5 sm:mb-8" />
		</div>

		<!-- Achievements -->
		<div class="bg-primary-light dark:bg-ternary-dark">
			<div>
				<div class="mx-auto">
					<Achievements class="mx-auto p-8" />
				</div>
			</div>
		</div>

		<!-- Erfolgsstrategie -->
		<div class="container mx-auto">
			<Erfolgsstrategie class="mb-5 sm:mb-8" />
		</div>

		<!-- Clients -->
		<div class="container mx-auto mb-5 py-10">
			<div class="text-center">
				<p class="text-2xl sm:text-5xl font-medium mb-2 text-ternary-dark dark:text-ternary-light">
					Unsere Mitglieder
				</p>
			</div>
			<AboutClients />
		</div>

		<!-- AboutHendrik -->
		<div class="container mx-auto">
			<AboutHendrik class="mb-5 sm:mb-8" />
		</div>

		<!-- CallToAction -->
		<div>
			<CallToAction class="mb-5 sm:mb-8" />
		</div>
	</div>
</template>

<style scoped></style>
