<script>
import feather from 'feather-icons';
export default {
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  }
};
</script>

<template>
  <div>
    <br />
    <br />
    <br />
    <transition name="fade">
      <div>
        <div class="absolute bg-black opacity-70 inset-0 z-0 h-screen"></div>
        <div class="w-full max-w-lg p-3 relative mx-auto my-auto rounded-xl shadow-lg bg-white">
          <div>
            <div class="text-center p-3 flex-auto justify-center leading-6">
              <i class="w-16 h-16 flex items-center text-orange-500 mx-auto" :data-feather="'alert-triangle'"></i>
              <h2 class="text-2xl font-bold py-4">Seite nicht verfügbar</h2>
              <p class="text-md text-gray-500 px-8">
                Diese Seite ist zurzeit nicht verfügbar, versuche es später erneut.
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}
</style>
