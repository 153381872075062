<script>
import Button from '../../components/reusable/Button.vue';
import LazyYoutube from "@/components/reusable/LazyYoutube.vue";

export default {
	name: 'HomeVideo',
	components: {
		Button,
		LazyYoutube
	},
};
</script>

<template>
	<!-- Content -->
	<section class="pt-10 sm:pt-14">
		<!-- Projects grid title -->
		<div class="text-center">
			<p class="py-2 text-2xl sm:text-4xl font-medium mb-2 text-ternary-dark dark:text-ternary-light">
				Durch ein einfaches <b>Schritt für Schritt System</b> zu mehr Dates mit attraktiven Frauen
			</p>
			<br />
			<div class="flex justify-center">
				<LazyYoutube :youtubeUrl="'8gyPrIEHbI0'" :youtubeClass="'w-8/12'"
					:title="'Die beste Möglichkeit Frauen kennenzulernen!'" />
			</div>
			<br />

			<!-- Load calendar button -->
			<div class="mt-2 sm:mt-5 flex justify-center">
				<a href="https://calendly.com/hendrik-mati/30min"
					class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
					target="__blank">
					<Button title="Buche jetzt dein kostenloses Beratungsgespr&auml;ch" />
				</a>
			</div>
		</div>
	</section>
</template>

<style scoped></style>
